//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { mapState, mapGetters, mapActions } from 'vuex';
import constant from '~/const';
import Filters from './AccountLogFilters.vue';

import tableConfig from './tableConfig';

export default {
  components: { Filters },

  data() {
    return {
      tableConfig,
      constant,
      selection: [],
    };
  },

  computed: {
    ...mapState('common', ['generalLoader']),
    ...mapState('login', ['currentAdmin']),
    ...mapState('accountLog', ['page', 'pages', 'sort']),
    ...mapState('accountLog', { data: 'userData' }),

    ...mapGetters('common', ['itemsPerPage']),
  },

  methods: {
    ...mapActions('common', ['setGeneralLoader', 'setGeneralProgress', 'setSuccessNotification', 'setErrorNotification']),
    ...mapActions('accountLog', {
      loadUserDataAction: 'loadUserData',
      setPageAction: 'setPage',
      setSortAction: 'setSort',
    }),

    parseRowData(row) {
      if (row && this.$utils.getAppType() === 'p2pay') return JSON.parse(row.data);
      return row.data;
    },

    async loadData() {
      this.setGeneralProgress(true);
      this.loader = true;
      try {
        // Определяем, какой ключ использовать в зависимости от условия
        const idKey = this.$utils.getAppType() === 'p2pay' ? 'adminId' : 'adminIdList';
        const idValue = [this.currentAdmin.id];

        // Вызываем метод loadDataAction с правильным ключом и значением
        await this.loadUserDataAction({ [idKey]: idValue });
      } catch (error) {
        this.setErrorNotification(error.message);
      }
      this.setGeneralProgress(false);
      this.loader = false;
    },

    applyFilters() {
      const { page } = this;
      if (page > 1) {
        this.setPageAction(1);
        this.$router.replace({ query: {} });
      }

      this.loadData();
    },

    applySort(data) {
      this.setSortAction(data);
      this.loadData();
    },

    setPage(value) {
      this.setPageAction(value);

      const extendedRequestData = {
        getTotal: false,
      };

      this.loadData(extendedRequestData);
    },

    actionHandler({ action, payload }) {
      this[action](payload);
    },

    getStatus(id) {
      const { ADMIN_STATUS_LIST } = constant.admin;
      return this.$utils.getCaption(ADMIN_STATUS_LIST, id);
    },
  },
};

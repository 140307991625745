//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { mapState, mapGetters, mapActions } from 'vuex';
import constant from '~/const';
import Filters from './AccountReportsFilters.vue';

import tableConfig from './tableConfig';

export default {
  components: { Filters },

  data() {
    return {
      tableConfig,
      constant,
      selection: [],
      loader: false,
      loadingId: 0,
    };
  },

  computed: {
    ...mapState('common', ['generalLoader']),
    ...mapGetters('common', ['itemsPerPage']),

    ...mapState('accountReports', ['page', 'pages', 'data', 'sort']),

    type() {
      return this.$utils.getAppType();
    },
  },

  methods: {
    ...mapActions('common', ['setGeneralLoader', 'setGeneralProgress', 'setSuccessNotification', 'setErrorNotification']),
    ...mapActions('accountReports', {
      loadDataAction: 'loadData',
      getReportAction: 'getReport',
      setPageAction: 'setPage',
      setSortAction: 'setSort',
    }),

    async loadData(data = {}) {
      this.setGeneralProgress(true);
      this.loader = true;
      await this.loadDataAction(data);
      this.setGeneralProgress(false);
      this.loader = false;
    },

    getStatus(row) {
      if (this.type !== 'p2pay') return this.$utils.getCaption(this.constant.reports.REPORT_STATUS_LIST, row.status);
      return this.$utils.getCaption(this.constant.reports.P2PAY_REPORT_STATUS_LIST, row.status);
    },

    applyFilters() {
      const { page } = this;
      if (page > 1) {
        this.setPageAction(1);
        this.$router.replace({ query: {} });
      }

      this.loadData();
    },

    applySort(data) {
      this.setSortAction(data);
      this.loadData();
    },

    setPage(value) {
      this.setPageAction(value);

      const extendedRequestData = {
        getTotal: false,
      };

      this.loadData(extendedRequestData);
    },

    actionHandler({ action, payload }) {
      this[action](payload);
    },

    async downloadReport({
      type,
      Id,
      id,
      file,
    }) {
      this.setGeneralLoader(true);
      this.loadingId = Id || id;
      try {
        const { data } = await this.getReportAction({
          reportType: type,
          reportId: this.loadingId,
        });
        if (this.type === 'p2pay') {
          this.$utils.downloadP2payReport(data, file);
        } else {
          this.$utils.downloadReport(data, file);
        }
        this.setSuccessNotification('Report downloaded');
      } catch (error) {
        this.setErrorNotification(error.message);
      }
      this.setGeneralLoader(false);
      this.loadingId = 0;
    },

    isButtonLoading(id) {
      const { loadingId } = this;
      return loadingId === id;
    },
  },
};

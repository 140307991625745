//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { mapActions, mapState } from 'vuex';
import AccountReports from '~/views/Account/AccountReports/AccountReports.vue';
import AccountLog from '~/views/Account/AccountLog/AccountLog.vue';
import AccountInfo from '~/views/Account/AccountInfo/AccountInfo.vue';

export default {
  components: {
    AccountReports,
    AccountLog,
    AccountInfo,
  },

  computed: {
    ...mapState('login', ['currentAdmin']),
  },

  async mounted() {
    this.setGeneralLoader(true);
    // Определяем, какой ключ использовать в зависимости от условия
    const idKey = this.$utils.getAppType() === 'p2pay' ? 'adminId' : 'adminIdList';
    const idValue = [this.currentAdmin.id];
    await Promise.allSettled([
      this.loadAccountReports(),
      // Вызываем метод loadAccountLog с правильным ключом и значением
      this.loadAccountLog({ [idKey]: idValue }),
    ]);
    this.setGeneralLoader(false);
  },

  methods: {
    ...mapActions('common', ['setGeneralLoader']),
    ...mapActions('accountReports', {
      loadAccountReports: 'loadData',
    }),
    ...mapActions('accountLog', {
      loadAccountLog: 'loadUserData',
    }),
  },
};

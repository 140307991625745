//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { mapState, mapActions } from 'vuex';

export default {
  props: {
    isLoading: { type: Boolean, default: false },
  },

  data() {
    return {
      filters: {
        searchString: '',
        dateCreatedFrom: '',
        dateCreatedTo: '',
        createdFrom: '',
        createdTo: '',
      },
    };
  },

  computed: {
    ...mapState('accountLog', ['filterLists']),

    dateFrom: {
      get() {
        return this.$utils.getAppType() !== 'p2pay' ? this.filters.dateCreatedFrom : this.filters.createdFrom;
      },
      set(value) {
        if (this.$utils.getAppType() !== 'p2pay') {
          this.filters.dateCreatedFrom = value;
        } else {
          this.filters.createdFrom = value;
        }
      },
    },

    dateTo: {
      get() {
        return this.$utils.getAppType() !== 'p2pay' ? this.filters.dateCreatedTo : this.filters.createdTo;
      },
      set(value) {
        if (this.$utils.getAppType() !== 'p2pay') {
          this.filters.dateCreatedTo = value;
        } else {
          this.filters.createdTo = value;
        }
      },
    },
  },

  watch: {
    filters: {
      async handler(data) {
        await this.setFilters(data);
        this.$emit('update');
      },
      deep: true,
    },
  },

  created() {
    this.setFilters({});
  },

  methods: {
    ...mapActions('accountLog', ['setFilters']),
  },
};

//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { mapActions } from 'vuex';

export default {
  props: {
    value: { type: Boolean, default: false },
  },

  data() {
    return {
      twoFaPin: '',
      currentPassword: '',
      newPassword: '',
      showCurrentPassword: false,
      showNewPassword: false,
    };
  },

  computed: {
    model: {
      get() {
        const { value } = this;
        return value;
      },
      set(value) {
        this.$emit('input', value);
      },
    },

    isDisabled() {
      const { twoFaPin, currentPassword, newPassword } = this;
      return !(twoFaPin && currentPassword && newPassword);
    },
  },

  watch: {
    value(value) {
      if (!value) {
        this.twoFaPin = '';
        this.currentPassword = '';
        this.newPassword = '';
      }
    },
    twoFaPin(value) {
      const { currentPassword, newPassword } = this;
      if (
        value.length === 6
        && currentPassword
        && newPassword
      ) {
        this.change();
      }
    },
  },

  methods: {
    ...mapActions('common', [
      'setGeneralProgress',
      'setErrorNotification',
      'setSuccessNotification',
      'confirmAction',
    ]),
    ...mapActions('accountInfo', ['changePassword']),

    maxLengthCheck(event) {
      if (this.twoFaPin.length >= 6 && event.key !== 'Backspace' && event.key !== 'Delete') {
        event.preventDefault();
      }
    },
    change() {
      this.confirmAction({
        text: 'Are you sure you want to change password?',
        callback: async () => {
          this.setGeneralProgress(true);
          try {
            await this.changePassword({
              twoFaPin: this.twoFaPin,
              currentPassword: this.currentPassword,
              newPassword: this.newPassword,
            });
            this.setSuccessNotification('Password changed');
            this.showCurrentPassword = false;
            this.showNewPassword = false;
            this.$emit('input', false);
          } catch (error) {
            this.setErrorNotification(error.message);
          }
          this.setGeneralProgress(false);
        },
      });
    },
  },
};
